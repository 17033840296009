import React from 'react';
import loadable from '@loadable/component';

const LazyMessengerCustomerChat = loadable(() => import('react-messenger-customer-chat'));

const FaceBookMessenger = () => (
      <LazyMessengerCustomerChat
        pageId="103130611587653"
        appId="378480403182368"
        themeColor="#1565C0"
        minimized
        loggedInGreeting="Totally ready to help."
        loggedOutGreeting="We'll Totally get back to you, soon as we are online."
        greetingDialogDisplay="hide"
        version="8.0"
        language="en_GB"
        onCustomerChatDialogShow={async () => {
          const buildDataLayer = await import('data-layer');
          const { gaLoggedInLabel } = await import('state/selectors');
          const dataLayer = buildDataLayer.default();
          dataLayer.trackLanding({
            descriptor: 'show-messenger',
            label: `${gaLoggedInLabel()}`,
          });
        }}
        onCustomerChatDialogHide={async () => {
          const buildDataLayer = await import('data-layer');
          const { gaLoggedInLabel } = await import('state/selectors');
          const dataLayer = buildDataLayer.default();
          dataLayer.trackLanding({
            descriptor: 'hide-messenger',
            label: `${gaLoggedInLabel()}`,
          });
        }}
      />
);

export default FaceBookMessenger;
